
@import "./tu-design";
@import "../node_modules/bootstrap/scss/bootstrap";

$form-select-focus-border-color:  $bs-green;

.btn-group-xs>.btn,
.btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: 0px;
}

.tu-card {
    border-bottom: 3px solid $bs-green;
}

.border-danger {
    border-color: $red !important;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 40px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 40px;
    line-height: 40px;
    /* Vertically center the text there */
    background-color: #f5f5f5;
    color: $gray-600;
    font-size:13px;
}

.card-header {
    border-bottom: none !important;
}

.card-footer {
    border-top: none !important;
}

.link {
    cursor: pointer;
}

.fulllink {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.dotted {
    border-bottom: 1px $body-color dotted;
}
